:root {
  --offwhite: #FAF9F6;
  --jet: #333333;
  --red: #BB2200;
  --font: 'Trebuchet MS', sans-serif
}


.background {
  background-color: var(--offwhite);
  display: flex;
  flex-direction: column;
  font: var(--font);
}

/* Header */
.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 10;
  width: 100vw;
  min-height: 55pt;
}
.nav-scrollvis {
  height: 2px;
  background-color: var(--red);
  width: 100vw;
}
.left-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1%;
  text-decoration: none;
}
.app-logo {
  margin: 1%;
  height: clamp(30pt, 4.5vw, 60pt);
  pointer-events: none;
}
.etch-nav {
  color: var(--jet);
  font-family: var(--font);
  font-size: clamp(25pt, 4vw, 45pt); /*45pt*/
  font-weight: 1000;
  margin-left: 5%;
}
.etch-nav:hover {
  color: var(--red)
}
.right-nav {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: 15pt;
}
.nav-item {
  color: var(--jet);
  font-size: clamp(15pt, 2vw, 22pt); /* 20pt */
  white-space: nowrap;
  text-decoration: none;
  margin-right: 5%;
}
.nav-item:hover {
  color: var(--red)
}
@media (max-width: 500px) {
  .right-nav {
    display: none;
  }
}




/* General */
.section-title {
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 0%;

  font-size: clamp(25pt, 4vw, 40pt);
  text-align: center;
  align-content: center;
  font-family: var(--font);
  color: var(--jet);
  font-weight: 600;
}
.section-content {
  margin-left: 5vw;
  margin-right: 2%;
  margin-top: 5%;
  font-size: clamp(14pt, 1.75vw, 20pt);
}
.hr {
  height: 1px;
  width: 80%;
  border-radius: 10%;
  background-color: var(--jet);
  opacity: 20%;
  align-self: center;
}

/* What's Etch */
.demo-image {
  position: absolute;
  width: 250px;
  height: 450px;
}
.etch-desc {
  margin-left: calc(200pt - 10vw) /*(20pt)*/;
  width: 50%;
}
.whats-etch {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;

  margin-top: clamp(70pt, 15%, 170pt);
}
.section-spacing {
  margin-top: 93px;
  margin-bottom: 93px;
}
.mobile-demo {
  display: none;
}
@media (max-width: 800px) { /* applies when the viewport is <= 800px*/
  .hide-on-mobile {
    display: none;
  }
  .mobile-demo {
    display: block;
  }
  .etch-desc {
    width: auto;
  }
  .whats-etch {
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  .demo-image {
    margin-top: 5vh;
    width: 125px;
    height: 225px;
  }
  .section-spacing {
    margin-top: 40pt;
    margin-bottom: 10pt;
  }
  .mobile-add {
    margin-top: 90pt;
  }
  .etch-desc {
    margin-left: 20pt;
  }
}
@media (max-width: 500px) { /* for actual mobile */
  .section-title {
    margin-top: 20pt;
  }
  .etch-desc {
    margin-left: 0;
  }
  .mobile-demo {
    margin-right: 25%;
  }
}


/* Vision Bullets */
.bullet-container {
  margin-left: 20vw;
  margin-top: 2%;
}
.ux-bullet {
  margin-top: 4%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
}
.bullet-textblock {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 1%;
}
.bullet-title {
  font-size: clamp(20pt, 3vw, 25pt);
  font-weight: 500;
}
.bullet-text {
  margin-top: 1%;
  font-size: clamp(13pt, 2vw, 17pt);
  width: 70%;
}
.bullet-img {
  top: 3%;
  margin-right: 1%;
  float: right;
}
.img-sm{
  height: clamp(44pt, 11%, 83pt);
  width: clamp(44pt, 11%, 83pt);
  margin-left: 1%;
}
.img-lg {
  height: clamp(48pt, 12%, 90pt);
  width: clamp(48pt, 12%, 90pt);
}
@media (max-width: 800px) {
  .bullet-img {
    top: 5%;
    height: 40pt;
    width: 40pt;
  }
  .ux-bullet {
    align-items: start;
  }
  .bullet-container {
    margin-left: 10vw;
  }
}

.ux-bullet-mobile {
  display: none;
  margin-top: 4%;
  margin-left: 12pt;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
}
.bullet-text-mobile {
  display: none;
  margin: 1%;
  margin-top: 2%;
  font-size: 13pt;
  width: 90%;
}
@media (max-width: 500px) { /* for actual mobile */
  .ux-bullet-mobile, .bullet-text-mobile {
    display: flex;
  }
  .ux-bullet {
    display: none;
  }
  .bullet-container {
    margin-left: 0;
  }
  .img-sm{
    height: 30pt;
    width: 30pt;
    margin-left: 1%;
  }
  .img-lg {
    height: 40pt;
    width: 40pt;
  }
}


/* Journey */
.journey-img {
  width: 70%;
  margin-right: 1%;
  z-index: 5;
}
@media (max-width: 800px) {
  .journey-img {
    width: 100%;
  }
}

/* Early Access */

.early-text {
  font: var(--font);
  font-size: clamp(14pt, 2vw, 20pt);
  line-height: 1.5;
  margin: 3% 3% 0% 3%;
  width: 70%;
}
.tab-text {
  font-size: clamp(14pt, 1.5vw, 18pt);
}
.tabs {
  width: 70vw;
}
.mobile-tabs {
  display: none;
  width: 80%;
  align-self: center;
  margin-top: 5%;
}
.mobile-button {
  align-self: center;
  margin-top: 5%;
}

@media (max-width: 900px) {
  .tabs {
    display: none;
  }
  .mobile-tabs {
    display: block;
  }
}

@media (max-width: 500px) {
  .early-text {
    line-height: 1.5;
    margin: 3% 3% 0% 3%;
    width: 90%;
  }
}





.grid {
  align-self: center;
  margin-top: 3%;
  width:800px;
  height:400px;
  display:grid;
  grid-template-columns: 50% 50%;
  grid-row: 10% 10%;
}
.gridbox {
  margin:5px;
  border-radius:10px;
  color:#fff;
  font-size:40px;
  font-family:sans-serif;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-left {
  display: flex;
  justify-content: left;
  align-items: end;
}
.bottom-right {
  display: flex;
  justify-content: right;
  align-items: end;
}
.top-right {
  display: flex;
  justify-content: right;
  align-items: start;
}
.top-left {
  display: flex;
  justify-content: left;
  align-items: start;
}


.join-button {
  width: 100%;
  height: 50%;
  border-radius: 10px;
  background-color: white;
  border: 0px;
  filter: drop-shadow(0 0 0.1rem var(--jet));
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transform: scale(1);
  transition: transform .5s;
  z-index: 0;
}

.red-ds {
  filter: drop-shadow(0 0 0.1rem var(--red));
}

.join-button:hover {
  z-index: 2;
  transform: scale(1.5);
  background-color: var(--offwhite);
  opacity: 100%;
  filter: drop-shadow(0 0 0.1rem var(--red));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.join-header {
  width: 100%;
}

.join-header-text {
  font: var(--font);
  font-size: 20px;
  font-weight: 500;
  margin: 1%;
}

.join-para {
  font: var(--font);
  font-size: 16px;
  margin: 5%;
}

/* Footer */

.footer-box {
  margin-top: 0%;
  width: 100%;
  height: 15vh;
  background-color: var(--red);
  filter: brightness(50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-text {
  font-size: 18px;
  color: rgb(202, 202, 202);
  text-decoration: none;
  margin: 35px;
}
.footer-hover:hover{
  color: var(--offwhite)
}
