* {
  scroll-behavior: smooth !important;
 }


.nav-scrollvis {
  margin-top: 1.4vw;
  position: fixed;
  box-shadow: 0 8px 6px -6px var(--red);
  scale: .001 1;
  transform-origin: left;
  animation-name: scroll-vis;
  animation-timeline: scroll();
  z-index: 9;
}

@keyframes scroll-vis {
  to {scale: 1 1; }
} 

/*
.rotate45 {
  transform-origin: bottom left;
  animation-name: rotate-demo;
  animation-direction: alternate;
  animation-timeline: view();
}
@keyframes rotate-demo {
  0%, 40% {
    transform: rotate(-15deg) translateY(20px) translateX(0px);
  }
  60%, 100% {
    transform: none;
  }
}
*/


.move1 {
  animation-name: move1-demo;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes move1-demo {
  from, 35% {
    margin-left: 0%;
  }
  to {
    margin-left: 20%;
  }
}
.move2 {
  animation-name: move2-demo;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes move2-demo {
  from, 35% {
    margin-left: 10%;
  }
  to {
    margin-left: 20%;
  }
}

.move3 {
  animation-name: move3-demo;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes move3-demo {
  from, 35% {
    margin-left: 0%;
  }
  to {
    margin-left: 10%;
  }
}
.move4 {
  animation-name: move4-demo;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes move4-demo {
  from, 35% {
    margin-left: 20%;
  }
  to {
    margin-left: 10%;
  }
}






.section-anim{
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.bullet-wipe{
  animation-name: bullet-wipe;
  animation-fill-mode: forwards;
  animation-timeline: view();
}

@keyframes bullet-wipe {
  0%, 11% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  18.99% {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  19% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
  }
}
.title-wipe{
  color: hsl(0 0% 100% / .2);
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-image: linear-gradient(var(--red), var(--red));

  animation-name: title-wipe;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes title-wipe {
  0%, 14% {
    background-size: 0% 100%;
  }
  80%, 100% {
    background-size: 100% 100%;
  }
}

.text-fade{
  animation-name: text-fade ;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes text-fade {
  0%, 30% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}

.mobile-bullet-wipe{
  animation-name: bullet-wipe;
  animation-fill-mode: forwards;
  animation-timeline: view();
}

@keyframes bullet-wipe {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  9.99% {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  10% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
  }
}
.title-wipe-mobile{
  color: hsl(0 0% 100% / .2);
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-image: linear-gradient(var(--red), var(--red));

  animation-name: title-wipe ;
  animation-fill-mode: forwards;
  animation-timeline: view();
}

@keyframes title-wipe-mobile {
  10% {
    background-size: 0% 100%;
  }
  30%, 100% {
    background-size: 100% 100%;
  }
}
.text-fade-mobile{
  animation-name: text-fade ;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes text-fade {
  0%, 20% {
    opacity: 0;
  }
  40%, 100% {
    opacity: 1;
  }
}


/* Our Journey */

.timeline-wipe{
  animation-name: timeline-wipe;
  animation-fill-mode: forwards;
  animation-timeline: view();
}

@keyframes timeline-wipe {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

.scaleUp{
  animation-name: scaleUp;
  animation-fill-mode: forwards;
  animation-timeline: view();
}
@keyframes scaleUp {
  10% {
    scale: .6 .6;
    filter: blur(5px);
  }
  40% {
    scale: 1 1;
    filter: blur(0px);
  }
}

/* Early Access */

.join-text{
  animation-name: join-text;
  animation-fill-mode: forwards;
  animation-timeline: view();
}

@keyframes join-text {
  0% {
    filter: blur(5px);
  }
  40% {
    filter: blur(0px);
  }
}


#button1:hover > #text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#button2:hover > #text2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#button3:hover > #text3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#button4:hover > #text4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
